<template>
  <div v-if="profiles">
    <ag-grid-vue :columnDefs="columnDefs.value" :rowData="rowData" :rowHeight="70" :headerHeight="90"
      :enableCellTextSelection="true" :ensureDomOrder="true" :pagination="true" :paginationPageSize="countOnPage"
      :defaultColDef="defaultColDef" :suppressRowHoverHighlight="true" :suppressPaginationPanel="true"
      :suppressRowClickSelection="true" :suppressRowTransform="true" style="width: 100%" domLayout="autoHeight"
      @grid-ready="onGridReady" @cell-clicked="onCellClicked"></ag-grid-vue>

    <table-pagination :activeFilter="activeFilter" :gridApi="gridApi"
      :totalPages="gridApi ? gridApi.paginationGetTotalPages() : 0" :countOnPage="countOnPage" />
  </div>
  <div v-else class="table-preloader">
    <preloader />
  </div>
</template>


<script>
import VPagination from "@hennge/vue3-pagination";
import "ag-grid-community/dist/styles/ag-grid.css";
import { AgGridVue } from "ag-grid-vue3";
import { reactive, ref } from "@vue/reactivity";
import EditRenderer from "../../../components/Tables/CellRenderers/EditRenderer.vue";
import { useStore } from "vuex";
import { computed, watch, onBeforeMount } from "@vue/runtime-core";
import Preloader from "../../../components/Technical/Preloader.vue";
import TablePagination from "../../../components/OtherElements/TablePagination.vue";
import CustomTextFilter from "@/components/Tables/FiltersComponents/CustomTextFilter.vue";
import CustomSelectFilter from "@/components/Tables/FiltersComponents/CustomSelectFilter.vue";
import PassingLinkRenderer from "../../../components/Tables/CellRenderers/PassingLinkRenderer.vue";
import CustomCheckbox from "../../../components/Forms/Fields/CustomCheckbox.vue";
import useCheckRights from '../../../mixins/useCheckRights';
import getRusData from '../../../mixins/rusData';
import CustomSelectCurators from "../../../components/Tables/CellRenderers/CustomSelectCurator.vue";
import DeleteReference from "../../../components/Tables/CellRenderers/DeleteReference.vue";
import SaveReport from "../../../components/Tables/CellRenderers/SaveReport.vue";
import { useRouter } from "vue-router";


export default {
  name: "references-table",
  components: {
    VPagination,
    AgGridVue,
    EditRenderer,
    Preloader,
    TablePagination,
    PassingLinkRenderer,
    CustomSelectFilter,
    CustomTextFilter,
    CustomCheckbox,
    CustomSelectCurators,
    DeleteReference,
    SaveReport
  },

  setup(props, context) {
    const store = useStore(), 
     { getRight } = useCheckRights(), 
      router = useRouter(),
      gridApi = ref(null),
      columnApi = ref(null);

    const onGridReady = (params) => {
      gridApi.value = params.api;
      columnApi.value = params.columnApi;
    };
    const countOnPage = ref(15),
      isPreloader = ref(false),
      activeFilter = ref(false);
    const isPassingFilter = ref(false);


    onBeforeMount(() => {
      store.dispatch("curator/getCurators");
      store.dispatch("analytics/getReferenceProfiles")
    });

    const referenceProfiles = computed(() => {
      return store.state.analytics.referenceProfiles
    });

    const profiles = ref(referenceProfiles),
      rowData = computed(() => {
        return profiles.value.map((passing) => {
          return {
            id: passing.id,
            title: passing.title,
            curator: passing.curator,
            calculated: passing.calculated !== 0 ? 'рассчитан' : 'не рассчитан',
            updated_at: getRusData(passing.updated_at)
          };
        });
      });

    const onCellClicked = ($event) => {
      $event.data.title == $event.value ? openReference($event.data.id, true) : null
    }
    const openReference = (id, readOnly) => {
      context.emit("openReference", { id, readOnly });
    }

    const getSelectValue = ($event, rowIndex) => {
      const curatorId = $event.id !== 'Не выбран' ? $event.id : null;
      const passingProfile = rowData.value[rowIndex]

      let profile = profiles.value.filter((profile) => {
        return profile.id == passingProfile.id
      });

      const newProfile = JSON.parse(JSON.stringify(profile[0]))

      newProfile.curator_id = curatorId

      newProfile.company_id = store.state.profile.profile.company.id

      store.dispatch("analytics/updateReferenceProfile", newProfile)
    };

    const deleteItem = (id) => {
      store.dispatch("analytics/deleteReferenceProfile", id)
    };

    const saveReport = (id) => {
      store.dispatch("analytics/getReferenceReport", id).then(()=>{
        router.push(`/profiles/report/${id}`);
      })
    }

    const columnDefs = reactive({
      value: [
        {
          field: "id",
          hide: true,
        },
        {
          headerName: 'Название',
          field: "title",
        },
        {
          headerName: 'Куратор',
          field: "curator",
          wrapText: true,
          cellRenderer: "CustomSelectCurators",
          cellRendererParams: {
            callback: getSelectValue,
          },
        },
        {
          headerName: 'Статус',
          field: "calculated"
        },
        {
          cellRenderer: "SaveReport",
          cellRendererParams: {
            text: "Скачать отчет",
            callback: saveReport

          },
        },
        {
          cellRenderer: "DeleteReference",
          cellRendererParams: {
            callback: deleteItem,
          },
        },

      ],
    });


    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
    };

    watch(isPassingFilter, () => {
      gridApi.value.onFilterChanged();
      activeFilter.value = !activeFilter.value;
    });


    return {
      countOnPage,
      isPreloader,
      profiles,
      isPassingFilter,

      columnDefs,
      rowData,
      defaultColDef,
      gridApi,
      onGridReady,
      columnApi,
      activeFilter,
      getRusData,
      getRight,
      openReference,
      onCellClicked
    };
  },
};
</script>

<style lang="scss" scoped>
.filters {
  padding: 10px 8px;
}
</style>
