<template>
  <button v-if="getRight('deleteReferences')" @click="params.callback(params.data.id)" class="btn">
    <i class="fal fa-trash"></i>
  </button>
</template>

<script>
import useCheckRights from '../../../mixins/useCheckRights';

export default {
  setup() {
   const { getRight } = useCheckRights()
   return {
      getRight,
    };
  }
}
</script>