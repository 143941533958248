<template>
  <div>
    <button  v-if="params.data.calculated == 'рассчитан'" class="btn btn-accent" @click="params.callback(params.data.id)">{{ params.text }}</button>
  </div>
</template>

<script>

export default {
  setup() {
    return {
    }
  },
};
</script>
