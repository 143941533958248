<template>
    <div class="curatorsField">
        <Select2 v-model="myValue" 
        :options="['Не выбран', ...myOptions]"
        :settings="{ width: '150px' }"
            @select="params.callback($event, params.rowIndex)">
        </Select2>
    </div>
</template>
<script>
import Select2 from 'vue3-select2-component';
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
    name: 'select-curator',
    components: {
        Select2
    },
    data(props) {
        const store = useStore()

        const curators = computed(() => { return store.state.curator.curators });
        const currentCuratorID = props.params.data.curator

        let currentCurator = curators.value.find((curator) => { return curator.id == currentCuratorID });

        const myOptions = computed(() => {
            return curators.value.map((passing) => {
                return {
                    id: passing.id,
                    text: passing.name,
                };
            });
        })
        
        return {
            myOptions,
            myValue: currentCurator ? currentCurator.id : ''
        }
    },
}
</script>

<style>
.curatorsField {
    display: flex;
    align-items: center;
}

div.form-group {
    justify-content: left !important;
}
</style>
